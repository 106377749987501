export default {
  required: { required: true, message: '必填' },
  // 身份证格式
  idCard: {
    pattern: /^(1[1-5]|2[1-3]|3[1-7]|4[1-6]|5[0-4]|6[1-5]|71|8[1-2])\d{4}(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[1-2]\d|3[0-1])\d{3}[\dxX]$/,
    message: '身份证号格式错误'
  },
  // 营业执照
  bussinessCard: {
    pattern: /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/,
    message: '营业执照格式错误'
  },
  // 手机号码
  mobile: {
    pattern: /^(1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8})?$/,
    message: '手机号码格式错误'
  },
  // 手机号码与座机
  mobileAndPhone: {
    pattern: /^((0\d{2,3}-\d{7,8})|(1[\d]\d{9}))$/,
    message: '手机/座机号码格式错误'
  }
}
