<template lang="pug">
el-dialog.full-box(
  title="停水通知"
  append-to-body
  width="50%"
  :visible.sync="visible")
  ul
    li.space-between
      .form-box
        DataformSelect(
          ref="select"
          v-model="formData.type"
          :options="typeOptions"
          @change="resetMatch")
        span.tip 大于
        .number-box
          DataformNumber(
            ref="input"
            v-model="formData.value"
            :min="1"
            :precision="0"
            @change="resetMatch")
          .text-danger.error-tip(v-if="!formData.value") {{ formData.type|renderSelect(typeOptions) }}必填且不为0
      ButtonGroup.form-button(
        :buttonList="[matchButton]")
    li.space-between(v-if="isMatched")
      .match-box
        label.match-label 欠费用水户数: #[strong {{ matchResult.usewaterCount }}户]
        label.match-label 可推送微信用户数: #[strong {{ matchResult.bindCount }}户]
      ButtonGroup.form-button(
        :buttonList="[pushButton]")
  ButtonGroup(
    slot="footer"
    :buttonList="buttonList")
</template>

<script>

export default {
  name: 'StopWater',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  filters: {
    renderSelect (value, options) {
      const option = options.find(i => i.value === value)
      return option.label
    }
  },
  computed: {
    visible: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  data () {
    return {
      isMatched: false,
      matchResult: {},
      matchButton: {
        name: 'match',
        label: '匹配',
        type: 'primary',
        func: () => {
          if (!this.formData.value) return
          return new Promise((resolve, reject) => {
            this.pushStopWater({ publish: false, data: this.formData })
              .then(data => {
                this.pushData = { ...this.formData }
                this.matchResult = data
                this.isMatched = true
                resolve(data)
              })
              .catch(reject)
          })
        }
      },
      pushData: {},
      pushButton: {
        name: 'push',
        label: '推送',
        type: 'primary',
        func: () => {
          this.formData = { ...this.pushData }
          return new Promise((resolve, reject) => {
            this.pushStopWater({ publish: true, data: this.pushData })
              .then(res => {
                this.$message.success('已加入推送队列')
                resolve('success')
                this.$emit('input', false)
              })
              .catch(reject)
          })
        }
      },
      typeOptions: [
        { label: '欠费账单数', value: 'unpayBillCount' },
        { label: '欠费总金额', value: 'unpayBillAmount' }
      ],
      formData: {
        value: 1,
        type: 'unpayBillCount'
      },
      formFields: []
    }
  },
  methods: {
    resetMatch () {
      this.isMatched = false
    },
    pushStopWater ({ publish, data }) {
      return new Promise((resolve, reject) => {
        const { type, value } = data
        this.$post({
          url: '/message/pushStopMessage',
          data: {
            [type]: value,
            publish
          }
        })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            resolve(res.data)
          })
          .catch(reject)
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.space-between
  display: flex
  flex-direction: row
  align-items: center
  line-height: 60px
  justify-content: space-between
  .form-box
    display: flex
    flex-direction: row
    align-items: center
    .tip
      margin: 0 8px
    .number-box
      position: relative
      .error-tip
        position: absolute
        bottom: -16px
        font-size: 12px
        line-height: 1
  .match-label
    color: #606266
    &+.match-label
      margin-left: 24px
</style>
