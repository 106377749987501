import regexs from '@/utils/regexs'

const nameMap = new Map([
  ['base', '基础信息'],
  ['totalSub', '总分表信息'],
  ['meter', '水表信息']
])

export const tableColumns = [
  { name: 'status', label: '状态', width: 80, relation: 'usewaterStatus', render: { type: 'select' } },
  { name: 'usewaterCode', label: '用水户号', width: 120 },
  {
    name: 'customerName',
    label: '用户',
    width: 160,
    render: (value, field, data) => {
      const { customerName, customerCode } = data
      return `${customerName}(${customerCode})`
    }
  },
  { name: 'usewaterAddress', label: '用水地址', minWidth: 200 },
  { name: 'lastReadingNum', label: '最新行码', width: 100 },
  { name: 'usenatureId', label: '用水性质', width: 120, relation: 'usenature', render: { type: 'select' } },
  { name: 'orgName', label: '水厂', width: 100 },
  { name: 'enable', label: '启停状态', width: 80, relation: 'enable', render: { type: 'select' } },
  { name: 'createTime', label: '建档日期', width: 120, render: { type: 'date' } },
  { name: 'deleteTime', label: '销户日期', width: 120, render: { type: 'date' } }
]

export const formFieldList = [
  {
    name: 'usewaterCode',
    label: '用水户号',
    form: {
      tag: 'input',
      rules: [regexs.required]
    },
    group: 'base'
  },
  {
    name: 'usewaterAddress',
    label: '用水地址',
    form: {
      tag: 'input',
      colSpan: 2,
      rules: [regexs.required]
    },
    group: 'base'
  },
  {
    name: 'billCycle',
    label: '缴费周期',
    form: {
      tag: 'select',
      relation: 'billCycle',
      rules: [regexs.required]
    },
    group: 'base'
  },
  // {
  //   name: 'lastBillTime',
  //   label: '最后出账月份',
  //   form: {
  //     tag: 'date',
  //     dateType: 'month',
  //     pickerOptions: {
  //       disabledDate (time) {
  //         return time.getTime() < Date.now()
  //       }
  //     },
  //     rules: [{ required: true, message: '必填' }]
  //   },
  //   group: 'base'
  // },
  {
    name: 'usenatureId',
    label: '用水性质',
    form: {
      tag: 'cascader',
      // colSpan: 2,
      relation: 'usenature',
      props: { checkStrictly: true },
      rules: [regexs.required]
    },
    group: 'base'
  },
  {
    name: 'lastReadingNum',
    label: '最新行码',
    form: {
      tag: 'number',
      rules: [regexs.required]
    },
    group: 'base'
  },
  {
    name: 'collectionId',
    label: '收款账户',
    form: {
      tag: 'select',
      relation: 'collections',
      rules: [regexs.required]
    },
    group: 'base'
  },
  {
    name: 'createTime',
    label: '建档日期',
    form: {
      tag: 'date',
      rules: [regexs.required]
    },
    group: 'base'
  },
  {
    name: 'usewaterName',
    label: '联系人姓名',
    form: {
      tag: 'input'
    },
    group: 'base'
  },
  {
    name: 'usewaterPhone',
    label: '联系电话',
    form: {
      tag: 'input',
      rules: [regexs.mobileAndPhone]
    },
    group: 'base'
  },
  {
    name: 'imprest',
    label: '预付费',
    form: {
      tag: 'switch',
      chains: ({ chains, values, value }) => {
        // 更新阈值类型
        chains.setState('limitType', (state) => {
          state.form.rules = value ? [regexs.required] : []
        })
        // 更新阈值
        chains.setState('limitValue', (state) => {
          state.form.rules = value ? [regexs.required] : []
        })
      }
    },
    group: 'base'
  },
  {
    name: 'balance',
    label: '余额',
    form: {
      tag: 'number'
    },
    group: 'base'
  },
  {
    name: 'limitType',
    label: '阈值类型',
    form: {
      tag: 'select',
      relation: 'limitType',
      rules: []
    },
    group: 'base'
  },
  {
    name: 'limitValue',
    label: '阈值',
    form: {
      tag: 'number',
      colSpan: 2,
      rules: []
    },
    group: 'base'
  },
  {
    name: 'usewaterAttchIds',
    label: '附件',
    form: {
      tag: 'file',
      typeLimit: ['jpg', 'jpeg', 'png'],
      colSpan: 3,
      resource: '/wfbase/upload',
      resType: 'USEWATER'
    },
    group: 'base'
  },
  {
    name: 'totalSub',
    label: '总分表',
    form: {
      tag: 'switch',
      chains: ({ chains, values, value }) => {
        // 更新总分表类型
        chains.setState('totalSubType', (state) => {
          state.form.visabled = value
          state.form.rules = value ? [regexs.required] : []
        })
        if (value) {
          // 开启总分表
          chains.setValue('totalSubType', 'TOTAL') // 默认总表
        } else {
          // 关闭总分表
          chains.setValue('totalSubType', '') // 清空类型
          chains.setValue('totalUsewaterId', '') // 清空所属总表
          chains.setValue('subMeterType', '') // 清空类型
        }
      }
    },
    group: 'totalSub'
  },
  {
    name: 'totalSubType',
    label: '总分表类型',
    form: {
      visabled: false,
      tag: 'select',
      relation: 'totalSubType',
      rules: [],
      chains: ({ chains, values, value }) => {
        chains.setState('totalUsewaterId', (state) => {
          // 分表/特殊总表时才显示所属总表
          const showTotalUsewaterId = ['SUB', 'SPEC_TOTAL'].includes(value)
          state.form.visabled = showTotalUsewaterId
          state.form.rules = showTotalUsewaterId ? [regexs.required] : []
        })
        chains.setState('subMeterType', (state) => {
          // 分表时才显示所属子表分类
          state.form.visabled = value === 'SUB'
          state.form.rules = value === 'SUB' ? [regexs.required] : []
        })
        chains.setValue('subMeterType', value === 'SUB' ? 'REAL' : '') // 清空类型
        chains.setState('wastage', (state) => {
          // 总表/特殊总表时显示损耗量
          const showWastage = ['TOTAL', 'SPEC_TOTAL'].includes(value)
          state.form.visabled = showWastage
          state.form.rules = showWastage ? [regexs.required] : []
        })
      }
    },
    group: 'totalSub'
  },
  {
    name: 'wastage',
    label: '损耗量',
    form: {
      visabled: false,
      tag: 'number',
      min: 0,
      precision: 0,
      rules: []
    },
    group: 'totalSub'
  },
  {
    name: 'totalUsewaterId',
    label: '所属总表',
    form: {
      visabled: false,
      tag: 'select',
      options: [],
      rules: []
    },
    group: 'totalSub'
  },
  {
    name: 'subMeterType',
    label: '子表分类',
    form: {
      visabled: false,
      tag: 'select',
      relation: 'subMeterType',
      rules: []
    },
    group: 'totalSub'
  },
  {
    name: 'meterNo',
    label: '水表编号',
    form: {
      tag: 'input',
      rules: [regexs.required]
    },
    group: 'meter'
  },
  {
    name: 'meterType',
    label: '水表类型',
    form: {
      tag: 'select',
      relation: 'meterType',
      rules: [regexs.required]
    },
    group: 'meter'
  },
  {
    name: 'diameter',
    label: '口径',
    form: {
      tag: 'select',
      relation: 'diameter',
      rules: [regexs.required]
    },
    group: 'meter'
  },
  {
    name: 'meterOutNo',
    label: '水表外码',
    form: {
      tag: 'input'
      // rules: [regexs.required]
    },
    group: 'meter'
  },
  {
    name: 'installNum',
    label: '装表行码',
    form: {
      tag: 'number',
      min: 0,
      rules: [regexs.required]
    },
    group: 'meter'
  },
  {
    name: 'maxnumber',
    label: '量程',
    form: {
      tag: 'number',
      min: 1,
      rules: [regexs.required]
    },
    group: 'meter'
  },
  {
    name: 'installDate',
    label: '装表日期',
    form: {
      tag: 'date',
      rules: [regexs.required]
    },
    group: 'meter'
  },
  {
    name: 'factory',
    label: '厂家',
    form: {
      tag: 'input'
    },
    group: 'meter'
  },
  {
    name: 'meterSealno',
    label: '铅封号',
    form: {
      tag: 'input'
    },
    group: 'meter'
  },
  {
    name: 'meterSelfno',
    label: '出厂编码',
    form: {
      tag: 'input'
    },
    group: 'meter'
  },
  {
    name: 'remark',
    label: '备注',
    form: {
      tag: 'input',
      inputType: 'textarea',
      colSpan: 2,
      minRows: 2,
      maxlength: 999,
      showWordLimit: true
    },
    group: 'meter'
  },
  {
    name: 'meterAttchIds',
    label: '水表附件',
    form: {
      tag: 'file',
      typeLimit: ['jpg', 'jpeg', 'png'],
      colSpan: 3,
      resource: '/wfbase/upload',
      resType: 'METER'
    },
    group: 'meter'
  }
]

export const getFormGroupConfig = (fieldList) => {
  const group = fieldList.reduce((res, field) => {
    if (!res[field.group]) res[field.group] = []
    res[field.group].push(field.name)
    return res
  }, {})
  const groupConfig = Object.keys(group).reduce((res, item) => {
    res.title.push(nameMap.get(item))
    res.split.push(group[item])
    return res
  }, { title: [], split: [] })
  return groupConfig
}
