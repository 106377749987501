<template lang="pug">
.wf-table.full-box
  .left-box
    label.blue-header 水厂
    dataform-tree.tree-box(
      v-model="orgIds"
      default-expand-all
      :options="waterWorksTree"
      @selectChange="handleWaterSelectChange"
    )
  .right-box
    DataTable(
      v-if="showTable"
      ref="dataTable"
      serverSide
      downloadResource="/usewater/export"
      resource="/usewater/list"
      :settingButtonList="['refresh', 'download']"
      :filterFields="filterFields"
      :columns="columns"
      :toolbarButtonList="toolbarButtonList"
      :operateButtonList="operateButtonList"
      :defaultSortBy="[{prop: 'usewaterCode', order: 'desc'}]"
      :defaultFilterBy="defaultFilterBy"
      :extraFilterBy="extraFilterBy"
      :operationWidth="100"
      :cellClassNameFunc="usewaterStatusClassFunc"
      @rowDblClick="handleRowDblClick")
  MeterChangeDialog(
    v-if="showDialog"
    v-model="showDialog"
    :data="dialogData"
    @finish="getData")
  StopWaterDialog(
    v-if="showStopWaterDialog"
    v-model="showStopWaterDialog")
  UsewaterTransferDialog(
    v-if="showTransferDialog"
    v-model="showTransferDialog"
    :data="transferData"
    @success="getData"
  )
</template>

<script>
import { tableColumns } from './config'
import { mapActions } from 'vuex'
import { multiColumnClassHandleFunc, classMap } from '@/utils/common.js'
import MeterChangeDialog from './components/MeterChangeDialog.vue'
import StopWaterDialog from './components/StopWaterDialog.vue'
import DataformTree from '@/components/Dataform/DataformTree'
import UsewaterTransferDialog from './components/UsewaterTransferDialog.vue'

export default {
  name: 'UsewaterManage',
  components: {
    MeterChangeDialog,
    StopWaterDialog,
    DataformTree,
    UsewaterTransferDialog
  },
  data () {
    const actionRights = this.$getPageActions([
      { name: 'add', url: '/file/usewaterManage/add' },
      { name: 'stop', url: '/file/usewaterManage/stop' },
      { name: 'start', url: '/file/usewaterManage/start' },
      { name: 'stopWater', url: '/file/usewaterManage/stopWater' },
      { name: 'edit', url: '/file/usewaterManage/edit' },
      { name: 'delete', url: '/file/usewaterManage/delete' },
      { name: 'changeMeter', url: '/file/usewaterManage/changeMeter' },
      { name: 'transfer', url: '/file/usewaterManage/transfer' },
      { name: 'detail', url: '/file/usewaterManage/detail' }
    ])
    return {
      showTable: false,
      defaultFilterBy: { status: 'START' },
      extraFilterBy: { orgId: [] },
      usewaterStatusClassFunc: multiColumnClassHandleFunc(['status', 'enable'], {
        status: classMap.usewaterStatus,
        enable: classMap.enable
      }),
      // 表格筛选表单配置
      filterFields: [{
        name: 'status',
        label: '状态',
        form: {
          tag: 'select',
          relation: 'usewaterStatus',
          inputWidth: '290px'
        }
      }, {
        name: 'enable',
        label: '启停状态',
        form: {
          tag: 'select',
          clearable: true,
          relation: 'enable',
          inputWidth: '290px'
        }
      }, {
        name: 'keyword',
        label: '关键词',
        form: {
          tag: 'input',
          like: true,
          placeholder: '用水户号/用户编号/用户姓名',
          inputWidth: '290px'
        }
      }, {
        name: 'usewaterAddress',
        label: '用水地址',
        form: {
          tag: 'input',
          like: true,
          inputWidth: '290px'
        }
      }, {
        name: 'usenatureId',
        label: '用水性质',
        form: {
          tag: 'cascader',
          relation: 'usenature',
          clearable: true,
          inputWidth: '290px'
        }
      }, {
        name: 'orgId',
        label: '水厂',
        showable: false,
        form: {
          tag: 'select',
          multiple: true,
          filterable: true,
          relation: 'waterWorks',
          clearable: true,
          inputWidth: '290px'
        }
      }, {
        name: 'createTime',
        label: '建档时间',
        form: {
          tag: 'date',
          dateType: 'daterange',
          inputWidth: '290px'
        }
      }],
      // 表格列配置
      columns: tableColumns,
      toolbarButtonList: [
        {
          label: '建档',
          name: 'add',
          type: 'primary',
          showabled: actionRights.add,
          func: () => {
            this.$router.push({
              path: 'usewaterManage/usewaterAdd'
            })
          }
        },
        {
          label: '停水通知',
          name: 'message',
          showabled: actionRights.stopWater,
          func: () => {
            this.showStopWaterDialog = true
          }
        }
      ],
      operateButtonList: [{
        label: '编辑',
        name: 'edit',
        type: 'text',
        textType: 'primary',
        showabled: (data) => actionRights.edit && data.status !== 'STOP',
        func: ({ data }) => {
          this.$router.push({
            name: 'usewaterEdit',
            query: {
              usewaterId: data.id
            }
          })
        }
      }, {
        label: '',
        name: 'more',
        type: 'text',
        textType: 'primary',
        icon: 'el-icon-more',
        dropdownButtons: [{
          label: '过户',
          name: 'transfer',
          type: 'text',
          textType: 'primary',
          showabled: (data) => actionRights.transfer && data.status !== 'STOP',
          func: ({ data }) => {
            this.transferData = data
            this.showTransferDialog = true
          }
        }, {
          label: '销户',
          name: 'delete',
          type: 'text',
          textType: 'danger',
          showabled: (data) => actionRights.delete && data.status !== 'STOP',
          func: this.handleDelete
        }, {
          label: '换表',
          name: 'changeMeter',
          type: 'text',
          textType: 'primary',
          showabled: (data) => actionRights.changeMeter && data.status !== 'STOP',
          func: ({ data }) => {
            this.getUsewaterData(data.id)
              .then(userwater => {
                userwater.oldReadingNum = userwater.lastReadingNum
                this.dialogData = userwater
                this.showDialog = true
              })
          }
        }, {
          label: '启用',
          name: 'start',
          type: 'text',
          textType: 'primary',
          showabled: (data) => actionRights.start && data.enable === 'STOP',
          func: this.handleStart
        }, {
          label: '停用',
          name: 'stop',
          type: 'text',
          textType: 'danger',
          showabled: (data) => actionRights.stop && data.enable === 'START',
          func: this.handleStop
        }, {
          label: '详情',
          name: 'info',
          type: 'text',
          textType: 'primary',
          showabled: actionRights.detail,
          func: ({ data }) => {
            const push = {
              name: 'usewaterDetail',
              params: {
                usewaterId: data.id
              }
            }
            window.globalEvent.emit('addTag', {
              push,
              tag: { title: `${data.usewaterCode}用水户详情` }
            })
          }
        }]
      }],
      // 水厂树绑定值
      orgIds: [],
      // 水厂树字典
      waterWorksTree: [],
      // 换表弹窗
      showDialog: false,
      dialogData: { meter: {} },
      showStopWaterDialog: false,
      // 过户弹窗
      showTransferDialog: false,
      transferData: {}
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    getData () {
      this.$refs.dataTable.reRenderTableData()
    },
    renderRelation () {
      this.getRelations(['usewaterStatus', 'usenature', 'waterWorks', 'enable', 'waterWorksTree', 'limitType'])
        .then(res => {
          this.waterWorksTree = res.waterWorksTree.map(item => {
            item.disabled = !item.parentId
            return item
          })
          this.$renderRelationColumns(res, [this.columns, this.filterFields])
          this.$nextTick(() => { this.showTable = true })
        })
    },
    // 双击进入详情
    handleRowDblClick (row) {
      const detailButton = this.operateButtonList[1].dropdownButtons[5]
      detailButton.showabled && detailButton.func({ data: row })
    },
    // 销户
    handleDelete ({ data }) {
      return new Promise((resolve, reject) => {
        this.$confirm('是否确认销户', '提示')
          .then(() => {
            this.$patch({
              url: `/usewater/cancel/${data.id}`
            }).then(res => {
              if (!res) {
                reject(new Error('error'))
                return
              }
              resolve('success')
              this.$message.success('销户成功!')
              this.getData()
            }).catch(reject)
          })
          .catch(reject)
      })
    },
    // 停用/启用
    startOrStopHandleFunc ({ keyword, sourceKey, data }) {
      return new Promise((resolve, reject) => {
        this.$confirm(`是否确认${keyword}`, '提示')
          .then(() => {
            this.$patch({
              url: `/usewater/${sourceKey}/${data.id}`
            }).then(res => {
              if (!res) {
                reject(new Error('error'))
                return
              }
              resolve('success')
              this.$message.success(`${keyword}成功!`)
              this.getData()
            }).catch(reject)
          })
          .catch(reject)
      })
    },
    handleStart ({ data }) {
      return this.startOrStopHandleFunc({
        keyword: '启用',
        sourceKey: 'start',
        data
      })
    },
    handleStop ({ data }) {
      return this.startOrStopHandleFunc({
        keyword: '停用',
        sourceKey: 'stop',
        data
      })
    },
    getUsewaterData (usewaterId) {
      return new Promise((resolve, reject) => {
        if (!usewaterId) {
          reject(new Error('error message: missing usewaterId'))
        } else {
          this.$get({
            url: `/usewater/get/${usewaterId}`
          })
            .then(res => {
              if (!res) {
                reject(res)
              } else {
                resolve(res.data)
              }
            })
            .catch(reject)
        }
      })
    },
    handleWaterSelectChange (data) {
      this.extraFilterBy = {
        orgId: data
      }
      this.$nextTick(() => {
        this.getData()
      })
    }
  },
  created () {
    this.renderRelation()
    window.globalEvent.on('renderUsewaterTable', this.getData)
  },
  beforeDestroy () {
    window.globalEvent.off('renderUsewaterTable', this.getData)
  }
}
</script>

<style lang="sass" scoped>
.wf-table
  flex: 1
  display: flex
  flex-direction: row
  background-color: #eee
  .left-box
    width: 250px
    height: 100%
    margin-right: 8px
    background-color: #fff
    padding: 8px
    overflow: auto
    .tree-box
      font-size: 13px
  .right-box
    width: calc( 100% - 258px )
    height: 100%
.drawerForm
  padding: 8px
</style>
