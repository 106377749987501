<template lang="pug">
el-select.full-width(
  v-model="currentValue"
  value-key="id"
  filterable
  remote
  placeholder="编号/姓名/证件号码/联系电话"
  :remote-method="handleRemoteMethod"
  :loading="searchLoading"
  @visible-change="handleSelectVisibleChange"
  @change="handleCustomerSelect")
  el-option(
    v-for="item in searchData.data"
    :key="item.id"
    :value="item"
    :label="`${item.customerName}(${item.customerCode})`")
  .datatablepage-pagination(v-if="searchData.total > 10")
    el-pagination(
      background
      :current-page.sync="pageIndex"
      :page-sizes="[10]"
      :page-size="pageSize"
      layout="total, prev, pager, next"
      :total="searchData.total"
      @current-change="pageIndexChange")
</template>

<script>

export default {
  name: 'UsewaterSelect',
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    currentValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  data () {
    return {
      searchKeyword: '',
      searchData: {
        data: [],
        total: 0
      },
      pageSize: 10,
      pageIndex: 1,
      searchLoading: false
    }
  },
  methods: {
    // 改变页码
    pageIndexChange (val) {
      // 改变页码
      this.pageIndex = val
      this.handleRemoteMethod(this.searchKeyword)
    },
    handleSelectVisibleChange (visible) {
      if (visible === false) this.pageIndex = 1
    },
    handleRemoteMethod (keyword) {
      this.searchKeyword = keyword
      const empty = {
        data: [],
        total: 0
      }
      return new Promise((resolve, reject) => {
        if (!keyword) {
          resolve(empty)
          return empty
        }
        this.searchLoading = true
        this.$get({
          url: '/customer/list',
          params: {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            filterBy: `keyword|like|${keyword};`
          }
        }).then(res => {
          this.searchLoading = false
          if (!res || !res.data) {
            resolve(empty)
          } else {
            resolve(res.data)
          }
        }).catch(e => {
          console.error(e)
          this.searchLoading = false
          resolve(empty)
        })
      }).then(res => {
        this.searchData = res
        return res
      })
    },
    handleCustomerSelect (customer) {
      this.$emit('change', customer)
    }
  }
}
</script>

<style lang="sass" scoped>
.datatablepage-pagination
  padding: 8px 4px
</style>
