<template lang="pug">
el-dialog.dialog-box(
  title="过户"
  append-to-body
  top="48px"
  width="80%"
  :visible.sync="visible"
  :before-close="handleClose")
  .form-box(v-if="showForm")
      .blue-header 选择新用户
      .step-content-box
        UsewaterSelect(
          v-model="newCustomer"
          @change="handleNewCustomerSelect")
      .blue-header 确认新用户信息
      DataformGroup(
        ref="formgroup"
        v-model="newCustomer"
        groupType="row"
        styleType="border"
        :colspanNum="3"
        :groupSplit="groupSplit"
        :groupTitle="['基本信息', '结算信息', '开票信息']"
        :formFields="customerFormFields")
      .blue-header 填写过户资料
      .step-content-box
        DataForm(
          ref="form"
          v-model="formData"
          type="grid"
          :gridspanNum="3"
          gridFlow="row"
          :inlineGrid="true"
          :formFields="formFields")
  ButtonGroup(
    slot="footer"
    :buttonList="buttonList")
</template>

<script>
import { mapActions } from 'vuex'
import { renderRelationColumns } from '@/utils/common.js'
import regexs from '@/utils/regexs'
import UsewaterSelect from './UsewaterSelect.vue'
import groupBy from 'lodash/groupBy'

const customerFormFields = [{
  name: 'customerCode',
  label: '用户编号',
  form: { tag: 'text', dot: true },
  group: 'base'
},
{
  name: 'customerName',
  label: '用户姓名',
  form: { tag: 'text', dot: true },
  group: 'base'
},
{
  name: 'phone',
  label: '联系电话',
  form: { tag: 'text', dot: true },
  group: 'base'
},
{
  name: 'address',
  label: '联系地址',
  form: { tag: 'text', dot: true, colSpan: 3 },
  group: 'base'
},
{
  name: 'idType',
  label: '证件类型',
  relation: 'idType',
  render: { type: 'select' },
  form: { tag: 'text', dot: true },
  group: 'base'
},
{
  name: 'idNum',
  label: '证件号码',
  form: { tag: 'text', dot: true, colSpan: 2 },
  group: 'base'
},
{
  name: 'idAtts',
  label: '证件附件',
  render: { type: 'file', showType: 'image' },
  form: { tag: 'text', dot: true, colSpan: 3 },
  group: 'base'
}, {
  name: 'settlementName',
  label: '结算名称',
  form: { tag: 'text', dot: true },
  group: 'settle'
},
{
  name: 'settlementBank',
  label: '结算银行',
  form: { tag: 'text', dot: true },
  group: 'settle'
},
{
  name: 'settlementPhone',
  label: '联系电话',
  form: { tag: 'text', dot: true },
  group: 'settle'
},
{
  name: 'settlementAccountNo',
  label: '账户号码',
  form: { tag: 'text', dot: true, colSpan: 3 },
  group: 'settle'
}, {
  name: 'invoiceName',
  label: '开票名称',
  form: { tag: 'text', dot: true },
  group: 'invoice'
},
{
  name: 'invoiceBank',
  label: '开票银行',
  form: { tag: 'text', dot: true },
  group: 'invoice'
},
{
  name: 'invoiceBankAccount',
  label: '银行账号',
  form: { tag: 'text', dot: true },
  group: 'invoice'
},
{
  name: 'invoiceTax',
  label: '纳税人识别号',
  form: { tag: 'text', dot: true, colSpan: 2 },
  group: 'invoice'
},
{
  name: 'invoicePhone',
  label: '联系电话',
  form: { tag: 'text', dot: true },
  group: 'invoice'
}]

const groupFormFields = groupBy(customerFormFields, 'group')

export default {
  name: 'UsewaterTransferDialog',
  components: { UsewaterSelect },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    // 用水户信息
    data: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    visible: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  data () {
    return {
      showForm: true,
      newCustomer: {},
      groupSplit: Object.keys(groupFormFields).map(key => {
        return groupFormFields[key].map(i => i.name)
      }),
      customerFormFields,
      formFields: [{
        name: 'transferAttchIds',
        label: '附件',
        form: {
          tag: 'file',
          rules: [regexs.required],
          typeLimit: ['jpg', 'jpeg', 'png'],
          colSpan: 3,
          resource: '/wfbase/upload',
          resType: 'USEWATER'
        }
      }, {
        name: 'transferRemark',
        label: '备注',
        form: {
          tag: 'input',
          inputType: 'textarea',
          colSpan: 3,
          minRows: 2,
          maxlength: 999,
          showWordLimit: true
        }
      }],
      formData: {},
      buttonList: [{
        name: 'confirm',
        label: '保存',
        type: 'primary',
        func: this.handleSave
      }, {
        name: 'close',
        label: '关闭',
        func: this.handleClose
      }]
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    handleNewCustomerSelect (customer) {},
    handleClose () {
      this.$emit('input', false)
      this.newCustomer = {}
      this.formData = {}
    },
    async handleSave () {
      try {
        if (!this.newCustomer.id) {
          this.$message.warning('请先选择新用户！')
          return
        } else if (this.newCustomer.id === this.data.customerId) {
          this.$message.warning('新用户与当前用户一致，请重新选择！')
          return
        }
        await this.$refs.form.$refs.dataform.validate()
        const res = await this.$post({
          url: '/usewater/transfer',
          data: {
            transferCustomerId: this.newCustomer.id,
            id: this.data.id, // 用水户ID
            ...this.formData
          }
        })
        if (!res) {
          return res
        }
        this.$message.success('过户成功!')
        this.handleClose()
        this.$emit('success')
        return 'success'
      } catch (error) {
        return error
      }
    },
    renderRelation () {
      this.getRelations(['idType'])
        .then(res => {
          renderRelationColumns(res, [this.customerFormFields])
          this.showForm = true
        })
    }
  },
  created () {
    this.renderRelation()
  }
}
</script>

<style lang="sass" scoped>
.form-box
.step-content-box
  padding: 8px 12px
</style>
<style scoped>
.dialog-box >>> .el-dialog {
  height: calc(100% - 98px);
}
.dialog-box >>> .el-dialog__body {
  height: calc(100% - 123px);
  overflow: auto
}
</style>
