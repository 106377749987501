<template lang="pug">
el-dialog.dialog-box(
  title="换表"
  append-to-body
  top="48px"
  width="80%"
  :visible.sync="visible")
  .form-box(v-if="showForm")
    .left-box
      label.blue-header 当前水表信息
      .meter-form
        DataInfo(
          :data="data.meter"
          :fields="currentFormFields")
    .right-box
      label.blue-header 新水表信息
      .meter-form
        DataForm(
          ref="form"
          v-model="formData"
          :formFields="formFields")
  ButtonGroup(
    slot="footer"
    :buttonList="buttonList")
</template>

<script>
import { mapActions } from 'vuex'
import { renderRelationColumns } from '@/utils/common.js'

export default {
  name: 'MeterChangeDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => ({
        meter: {}
      })
    }
  },
  computed: {
    visible: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  data () {
    return {
      showForm: false,
      currentFormFields: [{
        name: 'meterNo',
        label: '水表编号'
      },
      {
        name: 'meterType',
        label: '水表类型',
        relation: 'meterType',
        render: { type: 'select' }
      },
      {
        name: 'meterOutNo',
        label: '水表外码'
      },
      {
        name: 'diameter',
        label: '口径',
        relation: 'diameter',
        render: { type: 'select' }
      },
      {
        name: 'installNum',
        label: '装表行码'
      },
      {
        name: 'maxnumber',
        label: '量程'
      },
      {
        name: 'installDate',
        label: '装表日期',
        render: { type: 'date' }
      },
      {
        name: 'factory',
        label: '厂家'
      },
      {
        name: 'meterSealno',
        label: '铅封号'
      },
      {
        name: 'meterSelfno',
        label: '出厂编码'
      },
      {
        name: 'remark',
        label: '备注'
      },
      {
        name: 'meterAtts',
        label: '水表附件',
        render: { type: 'file', showType: 'image', colSpan: 3 }
      }],
      formFields: [{
        name: 'oldReadingNum',
        label: '旧表行码',
        form: {
          tag: 'number',
          min: 0,
          rules: [{ required: true, message: '必填' }]
        }
      }, {
        name: 'meterNo',
        label: '水表编号',
        form: {
          tag: 'input',
          rules: [{ required: true, message: '必填' }]
        }
      },
      {
        name: 'meterType',
        label: '水表类型',
        form: {
          tag: 'select',
          relation: 'meterType',
          rules: [{ required: true, message: '必填' }]
        }
      },
      {
        name: 'meterOutNo',
        label: '水表外码',
        form: {
          tag: 'input'
          // rules: [{ required: true, message: '必填' }]
        }
      },
      {
        name: 'diameter',
        label: '口径',
        form: {
          tag: 'select',
          relation: 'diameter',
          rules: [{ required: true, message: '必填' }]
        }
      },
      {
        name: 'installNum',
        label: '装表行码',
        form: {
          tag: 'number',
          min: 0,
          rules: [{ required: true, message: '必填' }]
        }
      },
      {
        name: 'maxnumber',
        label: '量程',
        form: {
          tag: 'number',
          min: 1,
          rules: [{ required: true, message: '必填' }]
        }
      },
      {
        name: 'installDate',
        label: '装表日期',
        form: {
          tag: 'date',
          rules: [{ required: true, message: '必填' }]
        }
      },
      {
        name: 'factory',
        label: '厂家',
        form: {
          tag: 'input'
        }
      },
      {
        name: 'meterSealno',
        label: '铅封号',
        form: {
          tag: 'input'
        }
      },
      {
        name: 'meterSelfno',
        label: '出厂编码',
        form: {
          tag: 'input'
        }
      },
      {
        name: 'remark',
        label: '备注',
        form: {
          tag: 'input',
          inputType: 'textarea',
          colSpan: 3,
          minRows: 2,
          maxlength: 999,
          showWordLimit: true
        }
      },
      {
        name: 'meterAttchIds',
        label: '水表附件',
        form: {
          tag: 'file',
          typeLimit: ['jpg', 'jpeg', 'png'],
          colSpan: 2,
          resource: '/wfbase/upload',
          resType: 'METER'
        }
      }],
      formData: {},
      buttonList: [{
        name: 'confirm',
        label: '保存',
        type: 'primary',
        func: this.handleSave
      }, {
        name: 'close',
        label: '关闭',
        func: () => {
          this.$emit('input', false)
          this.$emit('finish')
        }
      }]
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    async handleSave () {
      try {
        await this.$refs.form.$refs.dataform.validate()
        const res = await this.$post({
          url: '/meter/changeMeter',
          data: {
            usewaterId: this.data.id,
            ...this.$refs.form.formData,
            installDate: this.$day(this.formData.installDate).format('YYYY-MM-DD')
          }
        })
        if (!res) {
          return res
        }
        this.$message.success('换表成功!')
        this.$emit('input', false)
        this.$emit('finish')
        return 'success'
      } catch (error) {
        return error
      }
    },
    renderRelation () {
      this.getRelations(['meterType', 'diameter'])
        .then(res => {
          renderRelationColumns(res, [this.formFields, this.currentFormFields])
          this.showForm = true
        })
    }
  },
  created () {
    this.renderRelation()
    this.formFields[0].form.min = this.data.lastReadingNum
    this.formData.oldReadingNum = this.data.lastReadingNum
  }
}
</script>

<style lang="sass" scoped>
.form-box
  display: flex
  flex-direction: row
  .left-box
    flex-grow: 1
  .right-box
    flex-grow: 1
  .meter-form
    height: calc( 100% - 20px )
    padding: 8px 8px 0 0
</style>
<style scoped>
.dialog-box >>> .el-dialog {
  height: calc(100% - 98px);
}
.dialog-box >>> .el-dialog__body {
  height: calc(100% - 123px);
  overflow: auto
}
</style>
